<template>
  <validation-observer ref="simpleRules">
    <div>
      <b-row>
        <b-col md="3">
          <b-card>
            <h3>ลำดับขั้นชวนเพื่อน</h3>
            <b-form-group label="เลือกลำดับขั้น">
              <b-form-select
                v-model="InviteData.Level"
                :options="option"
              />
            </b-form-group>
            <form>
              <div
                v-for="item in InviteData.Level"
                :key="item"
              >
                <b-form-group :label="Object.keys(InviteData)[item]">
                  <validation-provider
                    #default="{ errors }"
                    :name="`percent${item}`"
                    rules="required"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="InviteData[Object.keys(InviteData)[item]]"
                        name="percent"
                        type="number"
                        required
                        :state="errors.length > 0 ? false : null"
                        placeholder="กรุณากำหนดเปอร์เซ็น"
                      />
                      <b-input-group-append is-text>
                        %
                      </b-input-group-append>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </form>

            <b-button
              v-b-modal.modal-lotto-ref
              variant="primary"
              @click="addRef"
            >บันทึก</b-button>
          </b-card>
        </b-col>

        <b-col lg="9">
          <b-row>
            <b-col lg="12">
              <b-card no-body>
                <b-row>
                  <b-col md="6">
                    <b-card-body>
                      <div class="p-1">
                        <h3>ประวัติ 5 รายการล่าสุด</h3>
                      </div>
                    </b-card-body>
                  </b-col>
                </b-row>

                <b-table
                  small
                  striped
                  hover
                  responsive
                  class="position-relative items-center"
                  :items="items"
                  :fields="historyFields"
                >
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                </b-table>
              </b-card>
            </b-col>

            <b-col lg="12">
              <b-card no-body>
                <b-row>
                  <b-col md="6">
                    <b-card-body>
                      <div class="p-1">
                        <h3>ข้อมูลจ่ายชวนเพื่อน</h3>
                      </div>
                    </b-card-body>
                  </b-col>

                  <b-col md="6">
                    <div
                      class="d-flex align-items-center justify-content-end mt-1"
                    >
                      <v-select
                        v-model="member"
                        name="member"
                        class="w-100 mr-2"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="username"
                        :options="optionsMember"
                        placeholder="User ID"
                      />

                      <b-button
                        variant="primary"
                        class="mr-1"
                        @click="GetRewardData()"
                      >
                        ค้นหา
                      </b-button>
                    </div>
                  </b-col></b-row>

                <b-table
                  small
                  striped
                  hover
                  responsive
                  class="position-relative items-center"
                  :per-page="perPage"
                  :items="RewardItems"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                >
                  <template #cell(index)="data">
                    {{ perPage * (currentPage - 1) + (data.index + 1) }}
                  </template>
                </b-table>
                <b-card-body
                  class="d-flex justify-content-between flex-wrap pt-0"
                >
                  <!-- page length -->
                  <b-form-group
                    label="Per Page"
                    label-cols="8"
                    label-align="left"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="text-nowrap mb-md-0 mr-1 ml-1 pb-1"
                  >
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage"
                      size="sm"
                      inline
                      :options="pageOptions"
                    />
                  </b-form-group>

                  <!-- pagination -->
                  <div>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mb-0 mr-1"
                      @input="GetRewardData()"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BPagination,
  BButton,
  BTable,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import moment from 'moment-timezone'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    BPagination,
    BTable,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      required,
      member: null,
      optionsMember: [],
      percent: [],
      InviteData: {
        Level: null,
        Level1: null,
        Level2: null,
        Level3: null,
        Level4: null,
        Level5: null,
        Level6: null,
        Level7: null,
        Level8: null,
      },
      totalRows: 1,
      perPage: 10,
      pageOptions: [10, 15, 20],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      option: [
        { text: 'กรุณาเลือกลำดับขั้น', value: null },
        { text: 'Level 1', value: 1 },
        { text: 'Level 2', value: 2 },
        { text: 'Level 3', value: 3 },
        { text: 'Level 4', value: 4 },
        { text: 'Level 5', value: 5 },
        { text: 'Level 6', value: 6 },
        { text: 'Level 7', value: 7 },
        { text: 'Level 8', value: 8 },
      ],
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'level', label: 'ลำดับขั้น' },
        { key: 'username', label: 'ยูเซอร์เนม' },
        { key: 'ref_username', label: 'ยูเซอร์เนม เพื่อน' },
        { key: 'reward', label: 'ยอด' },
        {
          key: 'startbalance',
          label: 'เครดิตก่อนจ่าย',
          formatter: value => this.Commas(value.toFixed(2)),
        },
        {
          key: 'endbalance',
          label: 'เครดิตหลังจ่าย',
          formatter: value => this.Commas(value.toFixed(2)),
        },
        {
          key: 'created_at',
          label: 'เวลาจ่าย',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
      ],
      historyFields: [
        { key: 'index', label: 'no.' },
        { key: 'Level', label: 'ลำดับขั้น' },
        {
          key: 'Level1',
          label: 'คอมมิสชั่นขั้นที่ 1',
          formatter: value => (value ? `${value} %` : '-'),
        },
        {
          key: 'Level2',
          label: 'คอมมิสชั่นขั้นที่ 2',
          formatter: value => (value ? `${value} %` : '-'),
        },
        {
          key: 'Level3',
          label: 'คอมมิสชั่นขั้นที่ 3',
          formatter: value => (value ? `${value} %` : '-'),
        },
        {
          key: 'Level4',
          label: 'คอมมิสชั่นขั้นที่ 4',
          formatter: value => (value ? `${value} %` : '-'),
        },
        {
          key: 'Level5',
          label: 'คอมมิสชั่นขั้นที่ 5',
          formatter: value => (value ? `${value} %` : '-'),
        },
        {
          key: 'Level6',
          label: 'คอมมิสชั่นขั้นที่ 6',
          formatter: value => (value ? `${value} %` : '-'),
        },
        {
          key: 'Level7',
          label: 'คอมมิสชั่นขั้นที่ 7',
          formatter: value => (value ? `${value} %` : '-'),
        },
        {
          key: 'Level8',
          label: 'คอมมิสชั่นขั้นที่ 8',
          formatter: value => (value ? `${value} %` : '-'),
        },
        { key: 'addby', label: 'เปลี่ยนโดย' },
        {
          key: 'created_at',
          label: 'วันที่',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
      ],
      items: [],
      RewardItems: [],
    }
  },
  mounted() {
    this.GetDataLast()
    this.GetData()
    this.getUserslist()
    this.GetRewardData()
  },
  methods: {
    getUserslist() {
      this.$http
        .get('https://api.rmtlotto.com/api/users/list')
        .then(response => {
          this.optionsMember = response.data
        })
        .catch(error => console.log(error))
    },
    addRef() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          Object.keys(this.InviteData).forEach((items, index) => {
            if (index > this.InviteData.Level) {
              this.InviteData[items] = null
            }
          })

          this.$http
            .post('https://api.rmtlotto.com/api/invaite/store', this.InviteData)
            .then(() => {
              this.GetData()
              this.Swal('success', 'สำเร็จ', 'บันทึกรายการเรียบร้อยค่ะ')
            })
            .catch(() => {
              this.Swal('error', 'ไม่สำเร็จ', 'บันทึกรายการไม่สำเร็จค่ะ')
            })
        }
      })
    },
    GetData() {
      this.$http
        .get('https://api.rmtlotto.com/api/invaite/show')
        .then(response => {
          const ResData = response.data
          if (ResData.success) {
            this.items = ResData.mes
            this.items.forEach((items, index) => {
              this.getAdmin(items.addby, index)
            })
          }
        })
        .catch(error => console.log(error))
    },
    GetRewardData() {
      const params = {
        username: this.member ? this.member.username : null,
        perPage: this.perPage,
        page: this.currentPage,
      }
      this.$http
        .get('https://api.rmtlotto.com/api/invaite/ShowReward', {
          params,
        })
        .then(response => {
          const ResData = response.data
          if (ResData.success) {
            this.RewardItems = ResData.mes.Data
            this.totalRows = ResData.mes.total
          }
        })
        .catch(error => console.log(error))
    },
    GetDataLast() {
      this.$http
        .get('https://api.rmtlotto.com/api/invaite/showlast')
        .then(response => {
          const ResData = response.data
          if (ResData.success) {
            this.InviteData.Level = ResData.mes.Level
            this.InviteData.Level1 = ResData.mes.Level1
            this.InviteData.Level2 = ResData.mes.Level2
            this.InviteData.Level3 = ResData.mes.Level3
            this.InviteData.Level4 = ResData.mes.Level4
            this.InviteData.Level5 = ResData.mes.Level5
            this.InviteData.Level6 = ResData.mes.Level6
            this.InviteData.Level7 = ResData.mes.Level7
            this.InviteData.Level8 = ResData.mes.Level8
          }
        })
        .catch(error => console.log(error))
    },
    getAdmin(addby, index) {
      this.$http
        .get(`https://api.rmtlotto.com/api/admin/show/${addby}`)
        .then(response => {
          this.items[index].addby = response.data.name
        })
        .catch(error => console.log(error))
    },
    Commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Swal(icon, title, mes) {
      this.$swal({
        icon,
        title,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped></style>
